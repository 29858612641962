<template>
  <div class="approvalPayments">
    <q-pull-to-refresh @refresh="refresh" class="pullRefresh">
      <DataCard
        :data="allFilters"
        :fields="fields"
        :actions="actions"
        @approve="approvePayment"
        @reject="rejectPayment"
        @cardClick="detailPayment"
        @filterR="filterBySupplierName"
        @filterB="filterByBranchCode"
        filterData
        v-if="cards"
      />
    </q-pull-to-refresh>
    <div class="RejectionReason">
      <q-dialog v-model="modalReason" persistent square full-width>
        <q-card class="bg-white">
          <q-card-section>
            <div class="text-h6">Rejeitar Pagamento</div>
            <div class="text-subtitle2">
              Favor informar o motivo da rejeição:
            </div>
          </q-card-section>
          <q-card-section>
            <q-input
              v-model="descriptionRejection"
              type="textarea"
              label="Descrição do Motivo"
              outlined
            />
          </q-card-section>
          <q-card-section>
            <div class="row justify-evenly q-mb-xl">
              <div class="col-auto">
                <q-btn
                  color="grey-8"
                  label="Cancelar"
                  @click="modalReason = false"
                />
              </div>
              <div class="col-auto">
                <q-btn color="red" label="Rejeitar" @click="confirmReject" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DataCard from "../../components/DataCard";

export default {
  components: {
    DataCard
  },
  data() {
    return {
      fields: [
        {
          field: "beneficiary",
          label: "",
          type: "string",
          class: "text-bold"
        },
        { field: "", label: "", type: "separator" },
        {
          field: "branch",
          label: "",
          type: "string",
          class: "text-body2"
        },
        {
          field: "value",
          label: "",
          type: "currency",
          class: "text-overline"
        }
      ],
      actions: [
        {
          label: "Aprovar",
          color: "green",
          icon: "check",
          eventEmit: "approve"
        },
        { label: "Rejeitar", color: "red", icon: "block", eventEmit: "reject" }
      ],
      filterWebR: null,
      filterWebB: null,
      filterR: sessionStorage.filterWebR,
      filterB: sessionStorage.filterWebB,
      cards: true,
      modalReason: false,
      descriptionRejection: null,
      paymentSelected: null
    };
  },
  computed: {
    ...mapState("payments", ["payments"]),

    allFilters() {
      //~> Declarando variável que armazenará o Retorno das requisições
      let payments = this.payments;

      switch (sessionStorage.sortWeb) {
        case "sSmLg":
          payments.sort((a, b) => a.amountOfOrder - b.amountOfOrder);
          break;
        case "sLgSm":
          payments.sort((a, b) => b.amountOfOrder - a.amountOfOrder);
          break;
        case "sNewOld":
          payments.sort((a, b) => b.issueDate - a.issueDate);
          break;
        case "sOldNew":
          payments.sort((a, b) => a.issueDate - b.issueDate);
          break;
        default:
          break;
      }

      if (this.filterR) {
        payments = payments.filter(payment => {
          return (
            payment.document
              .toUpperCase()
              .trim()
              .indexOf(this.filterR.toUpperCase().trim()) >= 0 ||
            payment.account
              .toUpperCase()
              .trim()
              .indexOf(this.filterR.toUpperCase().trim()) >= 0
          );
        });
      }

      if (this.filterB) {
        if (sessionStorage.filterWebB) {
          payments = payments.filter(pay => {
            return (
              pay.branchCode.trim().indexOf(sessionStorage.filterWebB.trim()) >=
              0
            );
          });
        } else {
          payments = payments.filter(pay => {
            return pay.branchCode.trim().indexOf(this.filterB.trim()) >= 0;
          });
        }
      }

      return payments.map(payment => {
        return {
          id: payment.id,
          beneficiary: `${payment.documentNumber} | ${
            payment.document
          } - ${payment.beneficiary.toUpperCase()}`,
          branch: `${payment.branchCode} - ${payment.branchName.toUpperCase()}`,
          dtmovi: payment.dtmovi,
          value: payment.value,
          paymentNumber: payment.documentNumber
        };
      });
    }
  },
  methods: {
    ...mapActions("payments", ["getPayments", "approvesRejectsPayments"]),
    ...mapMutations("shared", ["setNotification"]),
    approvePayment(payment) {
      this.sendResponse(payment, "approved", "APROVAR", "text-green");
    },
    rejectPayment(payment) {
      this.paymentSelected = payment;
      this.modalReason = true;
    },
    sendResponse(payment, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> o Lançamento ${payment.paymentNumber}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            const reasonReject =
              response === "rejected" ? this.descriptionRejection : "approved";
            await this.approvesRejectsPayments({
              payment,
              response,
              reasonReject
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.getPayments();
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    },
    confirmReject() {
      if (
        String(this.descriptionRejection).length < 8 ||
        this.descriptionRejection === null ||
        this.descriptionRejection === ""
      ) {
        this.$q.dialog({
          title: "Cicopal Aprova",
          message: "Favor detalhar o motivo da Rejeição deste pagamento!"
        });

        return;
      }

      this.sendResponse(
        this.paymentSelected,
        "rejected",
        "REJEITAR",
        "text-red"
      );
      this.modalReason = false;
    },
    detailPayment(paymentId) {
      this.$router.push({
        name: "paymentsDetail",
        params: { paymentId }
      });
    },
    filterBySupplierName(filterR) {
      this.filterR = filterR;
    },
    filterByBranchCode(filterB) {
      if (sessionStorage.filterWebB && !filterB) {
        this.filterB = sessionStorage.filterWebB;
      } else {
        this.filterB = filterB;
        sessionStorage.setItem("filterWebB", filterB);
      }
    },
    async refresh(done) {
      await this.getPayments();
      done();
    }
  },
  created() {
    this.getPayments();
  }
};
</script>
<style lang="scss">
.approvalPayments {
  width: 100%;
}
</style>
